import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Paper, Box, Typography, Container, CircularProgress } from '@material-ui/core';
import BonusPlan from './BonusPlan';
import { makeStyles } from '@material-ui/core/styles';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(1200),
            opacity: .94
        },
    },
    table: {
        maxWidth: 1000,
    },
    tableSmall: {
        maxWidth: 700,
    },

    upperText: {
        fontFamily: "Roboto",
    }
}));

const SimplePaper = (props) => {


    const classes = useStyles();

    const { authState, authService } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [empID, setEmpID] = useState(null);

    useEffect(() => {
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            authService.getUser().then(info => {
                setUserInfo(info);
                let okta_id = info.sub

                let config = {
                    method: 'get',
                    url: 'https://flex.okta.com/api/v1/users/' + okta_id,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'SSWS' + '00QJuVgJoZHE0A2q4DjGCW0iz5tf3hk0y4PnWyKLwE',
                    }
                };

                axios(config)
                    .then(function (response) {
                        const emp = response.data.profile.employeeNumber

                        setEmpID(emp);

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
        }
    }, [authState, authService]); // Update if authState changes

    if (!userInfo) {
        return <>
            <div className="App">
                <header className="App-header">
                    <CircularProgress size={80} thickness={8} />
                </header>
            </div>
        </>

    }



    return (
        authState.isAuthenticated ?
            (
                <div className="App">

                    <header className="App-header">


                        <CssBaseline />
                        <Container maxWidth="lg" style={{ margin: 'auto' }}>
                            <div className={classes.root}>
                                <Paper elevation={15}>


                                    <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        margin="10px"
                                    >

                                        <img src="./flex.jpg" title="Flex Logo" width='185' alt="Flex Logo" />
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="flex-end"
                                        margin="10px"
                                    >

                                    </Box>
                                    {!empID ?
                                        (
                                            <>

                                                <Typography component="div" >
                                                    <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                                                        Dear Employee:
                                                        <br />
                                                        <br />
                                                        The bonus payout process begins at the end of the fiscal quarter. Bonus-eligible employees can anticipate:
                                                        <br />
                                                        <br />
                                                        ~6 weeks from quarter close: Payout percentages and business performance metrics will be published at this website.
                                                        <br />
                                                        ~7 weeks from quarter close: Global Bonus Statements, which include the amount awarded, are published in Workday.
                                                        <br />
                                                        &gt;8 weeks from quarter close: Bonus payments are included in the next planned pay cycle for each country/site.
                                                        <br />
                                                        {/*  Q4FY22 Business Performance website under construction */}
                                                         {/* You may not have a Global Bonus plan assigned yet so please reach out to your manager or HR.<br /><br />
                                                        The detailed NEXTracker bonus plan performance is not available on this website so please request it from your manager or HR. */}
                                                        {/*  This website is under construction. Your bonus plan performance with detailed scorecard will be showing soon.<br />*/}
                                                    </Box>
                                                    {/* <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                                                        Please Contact your HR Business Partner.
</Box> */}
                                                </Typography>
                                            </>
                                        ) :
                                        (
                                            <BonusPlan employeeNumber={empID} name={userInfo.name} />

                                        )
                                    }


                                </Paper>


                            </div>
                        </Container>
                    </header>
                </div>
            )
            :
            (
                <>
                    <div className="App">
                        <header className="App-header">
                            {authService.login('/')}
                        </header>
                    </div>
                </>

            )


    );
}
export default (SimplePaper);