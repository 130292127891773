import React, { useState, useEffect } from "react";
import { Card, CardContent, Paper, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios'



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#009add',
    padding: '5px',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 16,
  },
  body: {
    fontSize: 21,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(1200),
      height: theme.spacing(125),
      opacity: .94
    },
  },
  table: {
    maxWidth: 1000,
  },
  tableSmall: {
    maxWidth: "100%",
    minWidth: "100%",
    float: "left",
    marginRight: "0px",
    borderRight: "1px",
    minHeight: "250px",
  },
  upperText: {
    fontFamily: "Roboto",
  }
}));




const BonusPlan = (props) => {

  const { employeeNumber, name } = props
  const [plan, setPlan] = useState('');
  const [planData, setPlanData] = useState();
  const [planType, setPlanType] = useState('');


  useEffect(() => {

    let cadence = ""

    const fetchPlan = async () => {
      const request = await axios('https://bonusmetrics.flex.com/WebSvc/api/EmpId/' + employeeNumber + '?apptokn=' + 'Fl3x!T0k3n').then(response => {
        const empPlans = response.data;


        const planId = empPlans ? (empPlans.BONUS_PLAN_NAME).replace(/[*/\//]/g, '') : null;


        planId ? setPlan(planId) : setPlanType("NoMatch")

        const planUrl = 'https://bonusmetrics.flex.com/WebSvc/api/bonusPlan/' + planId + '?apptokn=' + 'Fl3x!T0k3n'

        const getUrl = axios(planUrl).then(response => {
          const planData = response.data;

          setPlanData(planData);
          if (planData) {

            if (planData.bonusPlan.includes("Quarterly")) {
              cadence = "Quarterly"
              setPlanType(cadence);
            }
            else if (planData.bonusPlan.includes("Semi-Annual") || planData.bonusPlan.includes("Semi Annual")) {
              cadence = "Quarterly"
              setPlanType(cadence);
            }
            else if (planData.bonusPlan.includes("Annual")) {
              cadence = "Annual"
              setPlanType(cadence);
            }

          }
          else {
            cadence = "NoMatch"
            setPlanType(cadence);
          }


        });

      });
    }
    fetchPlan();
  }, []);






  function createData(metrics, weighting, achievement, threshold) {

    const percW = (weighting * 100).toFixed(2)
    const percA = (achievement * 100).toFixed(2)
    const weightedAchievement = waRow(percW, percA);
    const thresh = threshold != null ? (threshold* 100).toFixed(2) : ""

    const valid = !isNaN(weightedAchievement)

    if (valid === true) {

      return { metrics, percW, percA, weightedAchievement,thresh };
    }
    else
      return {};
  }

  const scorecard = planData && [
    (planData.OPW !== null || planData.OPR !== null) ? createData('OP', planData.OPW, planData.OPR, planData.OPThreshold) : {},
    (planData.RevenueW !== null || planData.RevenueR !== null) ? createData('Revenue', planData.RevenueW, planData.RevenueR, planData.RevenueThreshold) : {},
    (planData.FCFW !== null || planData.FCFR !== null) ? createData('Free Cash Flow', planData.FCFW, planData.FCFR, planData.FCFThreshold) : {},
    (planData.CSAW !== null || planData.CSAR !== null) ? createData('Corporate Metric', planData.CSAW, planData.CSAR) : {},
    (planData.LSAW !== null || planData.LSAR !== null) ? createData('Lifestyle BU', planData.LSAW, planData.LSAR) : {},
    (planData.CECSAW !== null || planData.CECSAR !== null) ? createData('CEC BU', planData.CECSAW, planData.CECSAR) : {},
    (planData.CDSAW !== null || planData.CDSAR !== null) ? createData('Consumer Devices BU', planData.CDSAW, planData.CDSAR) : {},
    (planData.CCCCAW !== null || planData.CCCCAR !== null) ? createData('Contractual Cash Conversion Cycle', planData.CCCCAW, planData.CCCCAR) : {},
    (planData.COPMAW !== null || planData.COPMAR !== null) ? createData('COP Margin', planData.COPMAW, planData.COPMAR) : {},
    (planData.ISAW !== null || planData.ISAR !== null) ? createData('Industrial BU', planData.ISAW, planData.ISAR) : {},
    (planData.ASAW !== null || planData.ASAR !== null) ? createData('Automotive BU', planData.ASAW, planData.ASAR) : {},
    (planData.HSSAW !== null || planData.HSSAR !== null) ? createData('Health Solution BU', planData.HSSAW, planData.HSSAR) : {},
    (planData.ITOW !== null || planData.ITOR !== null) ? createData('ITO, net of WCA', planData.ITOW, planData.ITOR, planData.ITOThreshold) : {},
  ];



  function waRow(weighting, achievement) {
    return (weighting * achievement) / 100;
  }

  function ccyFormat(num) {
    let numunfrmt = ""
    if (num === null || num === undefined)
      return;
    else
      numunfrmt = Number(num)
    return `${numunfrmt.toFixed(2)}`;
  }

  function decFormat(num) {
    if (num === null || num === undefined)
      return;
    else
      return `${num.toFixed(4)}`;
  }
  const classes = useStyles();


  /*  if (planData) { */
  switch (planType) {
    case "Annual":
      return (
        <>
          <Typography component="div" style={{ marginLeft: "22px" }} variant="caption">
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
              Dear <b>{name}</b>, <br /><br />
              The following explains how the Global Bonus Plan payout is calculated based on Flex’s cumulative business performance of FY'24.
            </Box>
            <Card variant="outlined" style={{ maxWidth: "98%", marginBottom: "25px" }}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                  <Box style={{ minWidth: "230px", float: "left" }}>Your bonus plan:</Box> <b>{plan}</b> <br />
                  <Box style={{ minWidth: "230px", float: "left" }}>Payout time:   </Box> {planData.Performance_Period}<br /> 
                  <Box style={{ minWidth: "230px", float: "left" }}>Your bonus payout %:</Box> {(planData.Payout * 100).toFixed(2)}%
                </Box>
                <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                  Your bonus payout is determined by the factors below:
                  <ol style={{ marginBottom: "0px" }}>
                    <li><b>Company performance</b></li>
                    Flex has a single, company-wide pool to fund all bonus payments globally, referred to as the “pool” and it determines the Flex Funding Factor.
                    The Pool is based on company performance and affordability, measured primarily by Corporate Operating Profit (Corp OP). Up to +/-20% difference between aggregate payouts (pre-funding) and OP funding is allowed.
                    At the end of FY'24 the Corporate OP achievement was {(planData.CorpOP * 100).toFixed(2)}%.
                    <br /><br />
                    <li><b>Business/organization performance</b></li>
                    Your business unit or organization performance is predefined as a scorecard with the below metrics and weightings.<br />
                    Your business/organization scorecard result at the end of FY'24 is {(planData.Scorecard_Result * 100).toFixed(2)}%.
                    <div style={{ paddingBottom: "20px", paddingTop: "20px", maxWidth: "1000px" }}>
                      <Box width='auto'>
                        <TableContainer component={Paper} elevation={2}>
                          <Table className={classes.tableSmall}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell style={{ minWidth: "180px" }}>Metrics</StyledTableCell>
                                <StyledTableCell align="right">Metric Weighting</StyledTableCell>
                                <StyledTableCell align="right">Achievement <br /><span style={{ fontSize: "12px" }}>(based on corporate goal attainment & the applicable performance curve)</span></StyledTableCell>
                                <StyledTableCell align="right">Threshold*</StyledTableCell>
                                <StyledTableCell align="right">Weighted Achievement</StyledTableCell>
                                <StyledTableCell align="right">Scorecard result</StyledTableCell>
                                <StyledTableCell align="right">Capped Scorecard Result**</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {scorecard.filter((row) => row.metrics).map((row, index) => (
                                <TableRow key={row.metrics}>
                                  <TableCell align='left' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.metrics}</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.percW}%</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.percA}%</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.thresh == "" ? "": row.thresh+'%'}</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{decFormat(row.weightedAchievement)}%</TableCell>
                                  {
                                    index == 0 ?
                                    <> 
                                  <TableCell  align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} rowSpan={scorecard.length}>{(planData.Scorecard_Result * 100).toFixed(2)}%</TableCell>
                                  <TableCell  align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} rowSpan={scorecard.length}>{(planData.Capped_Scorecard_Result * 100).toFixed(2)}%</TableCell></> : <></> }
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                          {/* <Table className={classes.tableSmallest} style={{ height: "100%" }}>
                            <TableHead>
                              <TableRow style={{ height: "84px" }}>
                                <StyledTableCell align="right">Scorecard result<br /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody >
                              <TableRow style={{ minHeight: "100%" }}>
                                <TableCell style={{ height: "100%", borderBottom: "0px", overflowX: "auto" }} align='center' colSpan={3}>{(planData.Scorecard_Result * 100).toFixed(2)}%</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table> */}
                        </TableContainer>
                        {(plan.includes("with ITO")) ? <Box textAlign="left" fontSize={14} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'}>*ITO, net of WCA replaced Free Cash Flow and CCCC for 2H FY23 for Grade 30 and above and site GMs. </Box> :'' }

                      </Box>
                      <i>**For the full year measurement period, if the threshold is not met for all metrics within each bonus scorecard, your scorecard achievement percentage will be capped at 100%. If the performance threshold has been met or surpassed for each metric, your scorecard achievement percentage will not be capped at 100% and will reflect actual performance.
                      <br/>*Site Plans Business Unit metrics have their threshold embedded into the BU achievement. </i>
                    </div>
                    <br />
                    {planData.Mgmt_Reason1 !== null || planData.Mgmt_Reason2 !== null || planData.Mgmt_Reason3 !== null || planData.Mgmt_Reason4 !== null || planData.Mgmt_Reason5 !== null ?
                      <>Management discretion for this plan is: {(planData.Mgmt * 100).toFixed(2)}% which is the result of the following factor(s):
                        <br /><br />
                        {planData.Mgmt_Reason1 ? <>{planData.Mgmt_Reason1} <br /><br /></> : null}
                        {planData.Mgmt_Reason2 ? <>{planData.Mgmt_Reason2} <br /><br /></> : null}
                        {planData.Mgmt_Reason3 ? <>{planData.Mgmt_Reason3} <br /><br /></> : null}
                        {planData.Mgmt_Reason4 ? <>{planData.Mgmt_Reason4} <br /><br /></> : null}
                        {planData.Mgmt_Reason5 ? <>{planData.Mgmt_Reason5} <br /><br /></> : null}
                      </> : null
                    }
                    <li><b>Your whole year bonus payout percentage is capped at 200% and calculated with the following modifiers:</b><br /><br /></li>
                    <span style={{ marginLeft: "19px" }}>Scorecard Result * Funding Factor * Management Discretion = Your Payout %</span><br /><br />
                    <Box margin="auto" width='fit-content'>
                      <Box style={{ minWidth: "230px", float: "left" }}>FY’24 Scorecard Result:</Box>{(planData.Capped_Scorecard_Result * 100).toFixed(2)}%<br />
                      <Box style={{ minWidth: "230px", float: "left" }}>Funding Factor:</Box> {planData.FF > 0 ? (planData.FF * 100).toFixed(2) + "%" : "N/A"}<br />
                      <Box style={{ minWidth: "230px", float: "left" }}>Management Discretion:</Box> {planData.Mgmt > 0 ? (planData.Mgmt * 100).toFixed(2) + "%" : "N/A"}<br />
                      <Box style={{ minWidth: "230px", float: "left" }}>Cap:</Box>200.00%<br />
                    </Box>
                    <br />

                    <Box margin="auto" width='fit-content'>

                    {(plan.includes("Consumer Devices") || plan.includes("Jaguariuna")) ? 
                       <Box ><span >Your FY’24 payout percentage is: { '('+(planData.Capped_Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'%) + '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span></Box> :
                        <Box ><span >Your FY’24 payout percentage is: { (planData.Capped_Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'% * '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span></Box>}<br />

                    {/* <span >Your FY’23 payout percentage is: { (planData.Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'% * '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span><br /><br /> */}

                    {/* <br /><span>We are very pleased by the overall positive business achievements and the bonus payout for our employees! </span><br /> */}
                    </Box>
                    {/* <Box margin="auto" width='fit-content'> */}
                      {/* <span>Congratulations on a great year!</span> */}
                    {/* <br /> */}
                    {/* </Box> */}
                  </ol>
                  {/* <br /> */}
                  <Box margin="auto" width='fit-content'>***</Box>
                  <span style={{ marginLeft: "59px" }}><b>Key:</b></span>
                  <Box width='fit-content' >
                    <Box><span style={{ float: "left",marginLeft: "119px" }}>Fiscal Year 2024 - FY'24</span><br /></Box>
                    <Box><span style={{ float: "left", marginLeft: "119px" }}>2<sup>nd</sup> Half of the Fiscal Year – 2H</span></Box>
                    <br /></Box>
                </Box>
                <br />
                <Box textAlign="left" m={2} fontSize={12} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} >
                  <b>Legal Disclaimer:</b> Final payout is based on cumulative performance vs. cumulative targets through the fiscal year measurement period (Q1 to Q4 FY'24). Per the terms and conditions of the Global Bonus policy, participation in the Flex Global Bonus Plan is conditioned upon your continued active employment through the date on which payments are earned and paid, unless a different treatment is mandated by local regulations.
                </Box>
              </CardContent>
            </Card>
          </Typography>
        </>
      );
    case "Quarterly":
      return (
        <>
          <Typography component="div" style={{ marginLeft: "22px" }} variant="caption">
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
              Dear <b>{name}</b>, <br /><br />
              The following explains how the Global Bonus Plan payout is calculated based on Flex’s cumulative business performance of FY’24. If you are on a semi-annual pay frequency, your individual payouts were trued up for prior FY’24 payments, as stated in your bonus letter. 
            </Box>
            <Card variant="outlined" style={{ maxWidth: "98%", marginBottom: "25px" }}>
              <CardContent style={{ paddingBottom: "0px" }}>
                <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal">
                  <Box style={{ minWidth: "230px", float: "left" }}>Your bonus plan:</Box> <b>{plan}</b> <br />
                   <Box style={{ minWidth: "230px", float: "left" }}>Payout time:</Box> <b>{(plan.includes("Semi-Annual") || plan.includes("Semi Annual")) ? "2H" : planData.Performance_Period}</b><br />
                  <Box style={{ minWidth: "230px", float: "left" }}>Your bonus payout %:</Box>  <span style={{ color: "red" }}><b>{(planData.Payout * 100).toFixed(2) + '%'}{/* {(plan.includes("Semi-Annual") || plan.includes("Semi Annual")) ? "2H bonus payout will be available after the end of Q4" :  (planData.Payout * 100).toFixed(2) + '%'} */}</b></span>
                </Box>
                <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                  Your bonus payout is determined by the factors below:
                </Box>
                {/*                 {planData.Performance_Period === "Q4" ? <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" style={{ border: "1px solid rgba(224, 224, 224, 1)", padding: "10px" }}>Your Q4 payout percentage is based on the total 2H21 performance (Q3 and Q4 cumulative performance) less your Q3 payout</Box> : ""}
 */}
                <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
                  <ol style={{ marginBottom: "0px" }}>
                    <li><b>Company performance</b></li>
                    Flex has a single, company-wide pool to fund all bonus payments globally, referred to as the “pool” and it determines the Flex Funding Factor.
                    The Pool is based on company performance and affordability, measured primarily by Corporate Operating Profit (Corp OP). Up to +/-20% difference between aggregate payouts (pre-funding) and OP funding is allowed. At the end of FY’24 the Corporate OP achievement was {(planData.CorpOP * 100).toFixed(2)}%.<br /><br />
                    <li><b>Business/organization performance</b></li>
                    Your business unit or organization performance is predefined as a scorecard with the below metrics and weightings.
                    Your business/organization scorecard result at the end of FY'24 is {(planData.Scorecard_Result * 100).toFixed(2)}%.
                    <div style={{ paddingBottom: "20px", paddingTop: "20px", maxWidth: "1000px" }}>
                      <Box width='auto'>
                        <TableContainer component={Paper} elevation={2}>
                          <Table className={classes.tableSmall}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell style={{ minWidth: "180px" }}>Metrics</StyledTableCell>
                                <StyledTableCell align="right">Metric Weighting</StyledTableCell>
                                <StyledTableCell align="right">Achievement <br /><span style={{ fontSize: "12px" }}>(based on corporate goal attainment & the applicable performance curve)</span></StyledTableCell>
                                <StyledTableCell align="right">Threshold*</StyledTableCell>
                                <StyledTableCell align="right">Weighted Achievement</StyledTableCell>
                                <StyledTableCell align="right">Scorecard result</StyledTableCell>
                                <StyledTableCell align="right">Capped Scorecard Result**</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {scorecard.filter((row) => row.metrics).map((row,index) => (
                                <TableRow key={row.metrics}>
                                  <TableCell align='left' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.metrics}</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.percW}%</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.percA}%</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{row.thresh == "" ? "": row.thresh+'%'}</TableCell>
                                  <TableCell align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{decFormat(row.weightedAchievement)}%</TableCell>
                                  {
                                    index == 0 ?
                                    <> 
                                  <TableCell  align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} rowSpan={scorecard.length}>{(planData.Scorecard_Result * 100).toFixed(2)}%</TableCell>
                                  <TableCell  align='center' style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} rowSpan={scorecard.length}>{(planData.Capped_Scorecard_Result * 100).toFixed(2)}%</TableCell></> : <></> }
                                </TableRow>                                
                              ))}
                            </TableBody>
                          </Table>
                          {/* <Table className={classes.tableSmallest} style={{ height: "100%" }}>
                            <TableHead>
                              <TableRow style={{ height: "84px" }}>
                                <StyledTableCell align="right">Scorecard result</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow style={{ minHeight: "100%" }}>
                                <TableCell style={{ height: "100%", borderBottom: "0px", overflowX: "auto" }} align='center' colSpan={3}>{(planData.Scorecard_Result * 100).toFixed(2)}%</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table> */}
                        </TableContainer>
                      </Box>
                      <i>**For the full year measurement period, if the threshold is not met for all metrics within each bonus scorecard, your scorecard achievement percentage will be capped at 100%.
                         If the performance threshold has been met or surpassed for each metric, your scorecard achievement percentage will not be capped at 100% and will reflect actual performance.
                      <br/>*Site Plans Business Unit metrics have their threshold embedded into the BU achievement. </i>
                    </div>
                      {planData.Mgmt_Reason1 !== null || planData.Mgmt_Reason2 !== null || planData.Mgmt_Reason3 !== null || planData.Mgmt_Reason4 !== null || planData.Mgmt_Reason5 !== null ?
                      <>Management discretion for this plan is: {(planData.Mgmt * 100).toFixed(2)}% which is the result of the following factor(s):
                        <br /><br />
                        {planData.Mgmt_Reason1 ? <>{planData.Mgmt_Reason1} <br /><br /></> : null}
                        {planData.Mgmt_Reason2 ? <>{planData.Mgmt_Reason2} <br /><br /></> : null}
                        {planData.Mgmt_Reason3 ? <>{planData.Mgmt_Reason3} <br /><br /></> : null}
                        {planData.Mgmt_Reason4 ? <>{planData.Mgmt_Reason4} <br /><br /></> : null}
                        {planData.Mgmt_Reason5 ? <>{planData.Mgmt_Reason5} <br /><br /></> : null}
                        
                      </> : null
                      }
                    <li><b>Besides the Scorecard Result, your whole year bonus payout percentage is capped at 200% and calculated with the following modifiers:</b></li>
                    
                    <span style={{ marginLeft: "19px" }}>Scorecard Result * Funding Factor * Management Discretion = Your Payout %</span><br /><br />
                    <Box margin="auto" width='fit-content'>
                    <Box style={{ minWidth: "230px", float: "left" }}>FY’24 Scorecard Result:</Box>{(planData.Capped_Scorecard_Result * 100).toFixed(2)}%<br />
                    <Box style={{ minWidth: "230px", float: "left" }}>Funding Factor:</Box> {planData.FF > 0 ? (planData.FF * 100).toFixed(2) + "%" : "N/A"}<br />
                    <Box style={{ minWidth: "230px", float: "left" }}>Management Discretion:</Box> {planData.Mgmt > 0 ? (planData.Mgmt * 100).toFixed(2) + "%" : "N/A"}<br />
                    <Box style={{ minWidth: "230px", float: "left" }}>Cap:</Box>200.00%<br />
                    </Box>
                    <br />
                   {/*  <br />
                    <span >Your {(plan.includes("Semi-Annual") || plan.includes("Semi Annual")) ? "" : planData.Performance_Period} bonus payout percentage is:  {(plan.includes("Semi-Annual") || plan.includes("Semi Annual")) ? "2H bonus payout will be available after the end of Q4" :  (planData.Payout * 100).toFixed(2)+'%'}</span><br /><br />
                    <br /> */}
                    
                    <Box margin="auto" width='fit-content'>
                       {(plan.includes("Consumer Devices") || plan.includes("Jaguariuna")) ? 
                       <Box ><span >Your 2H payout percentage is: { '('+(planData.Capped_Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'% * '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span></Box> :
                       <Box ><span >Your 2H payout percentage is: { (planData.Capped_Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'% * '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span></Box>}<br />


                    {/* <span >Your FY’23 payout percentage is: { (planData.Scorecard_Result * 100).toFixed(2) +'% * '+ (planData.FF * 100).toFixed(2)+'% * '+ (planData.Mgmt * 100).toFixed(2)+'% = '+  (planData.Payout * 100).toFixed(2)+'%'}</span><br /><br /> */}

                    {/* <br /><span>We are very pleased by the overall positive business achievements and the bonus payout for our employees! </span><br /> */}
                    </Box>
                    {/* <Box margin="auto" width='fit-content'>
                      <span>Congratulations on a great year!</span>
                    <br />
                    </Box> */}
                  </ol>
                  {/* <Box margin="auto" width='fit-content'>Thank you for your contributions to Flex. Let us continue working together to deliver a successful year</Box> */}
                  <Box margin="auto" width='fit-content'>***</Box>
                  <span style={{ marginLeft: "59px" }}><b>Key:</b></span>
                  <Box width='fit-content'>
                    <Box ><span style={{ float: "left", marginLeft: "119px" }}>Fiscal Year 2024 – FY'24</span><br /></Box>
                    {(plan.includes("Semi-Annual") || plan.includes("Semi Annual")) ?<Box ><span style={{ float: "left", marginLeft: "119px" }}>2<sup>nd</sup> Half of the Fiscal Year – 2H</span><br /></Box> :  <Box ><span style={{ float: "left", marginLeft: "119px" }}>4<sup>th</sup> Quarter of the Fiscal year – Q4</span></Box>  }
                    <br />
                    <br />
                  </Box>
                </Box>
                <br />
                <Box textAlign="left" m={2} fontSize={12} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} >
                  <b>Disclaimer:</b> Per the terms and conditions of the Global Bonus policy, participation in the Flex Global Bonus Plan is conditioned upon your continued active employment through the date on which payments are earned and paid, unless a different treatment is mandated by local regulations.
                </Box>
              </CardContent>
            </Card>
          </Typography>
        </>
      );
    case "NoMatch":
      return (
        <>

          <Typography component="div" >
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
           
               {/* You may not have a Global Bonus plan assigned yet so please reach out to your manager or HR.<br /><br />
            The detailed NEXTracker bonus plan performance is not available on this website so please request it from your manager or HR.  */}
              Dear Employee:
              <br />
              <br />
              The bonus payout process begins at the end of the fiscal period. Bonus-eligible employees can anticipate:
              <br />
              <br />
              ~6 weeks from fiscal period close: Payout percentages and business performance metrics will be published at this website.
              <br />
              ~7 weeks from fiscal period close: Global Bonus Statements, which include the amount awarded, are published in Workday.
              <br />
              &gt;8 weeks from fiscal period close: Bonus payments are included in the next planned pay cycle for each country/site.
              <br />
            </Box>
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
              {/* Please Contact your HR Business Partner. */}
            </Box>
          </Typography>
        </>
      );
    default:
      return (
        <>

          <Typography component="div" >
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
              Loading your Bonus Plan...
            </Box>
            <Box textAlign="left" m={2} lineHeight="normal" >
              <CircularProgress size={40} thickness={8} />
            </Box>
            <Box textAlign="left" m={2} fontSize={16} fontFamily={'Roboto", "Helvetica", "Arial", sans-serif'} lineHeight="normal" >
              If no information is provided please contact your HR Business Partner.
            </Box>
          </Typography>
        </>
      );
  }

};
export default (BonusPlan);